.client-list-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px; /* Space between elements */
    margin-bottom: 20px;
  }

.page-title {
    font-size: 24px;
    font-weight: 700;
    color: #282c34;
    margin-bottom: 20px;
  }
  
  .action-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px; /* Space between button and search bar */
    width: 100%;
  }

  .add-client-btn {
    font-size: 16px;
    padding: 8px 16px;
  }
  
  .search-container {
    position: relative;
    flex-grow: 1; /* Allows search input to take available space */
    max-width: 400px;
  }

  .search-input {
    width: 100%;
    padding: 8px 12px;
    padding-right: 40px; /* Space for the icon */
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .search-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #6c757d;
  }
  
  .table-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
    max-height: calc(100vh - 250px); /* Adjust to fit available space */
    overflow-y: auto;
  }
  
  .styled-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  
  .styled-table th {
    background-color: #000000;
    color: #fff;
    font-weight: bold;
    padding: 10px;
  }
  
  .styled-table td {
    padding: 10px;
  }
  
  .styled-table .client-link,
  .styled-table .pet-link {
    color: #3e4041;
    text-decoration: none;
  }
  
  .styled-table .client-link:hover,
  .styled-table .pet-link:hover {
    text-decoration: underline;
  }
  
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .styled-table tr:hover {
    background-color: #e9f5ff;
  }
  
  .transaction-btn {
    background-color: #28a745;
    border: none;
    color: white;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 4px;
  }
  
  .transaction-btn:hover {
    background-color: #218838;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .pagination-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 15px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
  
  .pagination-page-number {
    font-size: 16px;
  }