.volume-meters {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.volume-meter {
    text-align: center;
    margin: 20px;
}
