@media (max-width: 768px) {
    .purchase-general-container {
      font-size: 12px; /* Smaller font size for smaller screens */
    }
  }

.purchase-general-container {
    margin: 3%;
    font-family: Arial, sans-serif;
}

.search-filter {
    padding: 1%;
}

.search-filter input {
    width: 40%;
    height: 40px;
}

.search-filter button {
    margin-left: 1%;
    width: 20%;
}

.payment-modal {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000; /* To ensure it appears above other elements */
    width: 80%;
    max-width: 600px; /* Adjust as needed */
    max-height: 80vh;
    overflow-y: auto;
}

.payment-modal-close {
    float: right;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

.payment-modal table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.payment-modal th, .payment-modal td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.payment-modal input[type="number"] {
    width: 60px; /* Reduce width for two-digit input */
    padding: 5px;
}

.payment-modal tfoot {
    font-weight: bold;
}

.payment-modal tfoot td {
    border-top: 2px solid #000;
}

.icon-pay, .icon-delete, .icon-consult {
    font-size: 16px;
    cursor: pointer;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

table button {
    background: #007bff;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
}

button:hover {
    background-color: #e8e8e8;
}

form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #444;
}

input[type="number"], .react-datepicker-wrapper, .react-datepicker__input-container, .react-select__control {
    width: 100%;
    padding: 8px 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

.react-select__control {
    cursor: pointer;
}

.react-select__option--is-focused {
    background-color: #e9e9e9;
}

.react-select__option--is-selected {
    background-color: #ddd;
}

button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"] {
    margin-top: 10px;
}

.success-message {
    color: green;
    margin-top: 15px;
}

.table-responsive {
    max-height: 400px; /* Limit table height */
    overflow-y: auto; /* Add vertical scroll */
  }

  .pagination {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .pagination button {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .store-selection {
    margin-top: 1rem;
}
.store-selection div {
    display: table;
}

.store-selection > div > div > input[type="number"].store-split-input {
    width: 25%;
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

.store-selection label {
    margin-left: 0.5rem;
}

.validation-message {
    color: red;
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.allocated-amount {
    margin-top: 1rem;
    font-size: 1rem;
  }
  
.allocated-amount.match {
font-weight: bold;
color: black; /* Matches expected total */
}
  
.allocated-amount.mismatch {
font-weight: bold;
color: red; /* Indicates mismatch with totalToBePaid */
}

/* Highlight the total row */
tfoot tr {
    border-top: 2px solid #000;
}

tfoot td {
    font-weight: bold;
    padding: 8px;
    text-align: left;
}

.payment-modal .amount-paid-input {
    width: 100px !important;
    padding: 5px !important;
    margin: 0px !important;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: right;
    background-color: #f9f9f9; /* Optional: Differentiated background */
}