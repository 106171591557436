@media (max-width: 768px) {
    .charts-container {
        grid-template-columns: 1fr;
    }
}

.expense-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #fff; 
}

.header-dashboard {
    width: 100%;
}

.charts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
    width: 100%;
}

.chart {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.operating-expense {
    color:#333;
    width: auto;
}

.expense-dashboard h1 {
    color: #333;
    margin: 5px 0px;
}

.fixed-pie-chart {
    margin: 0;
}

.fixed-expense-breakdown h3 {
    color: #333;
    margin: 5px;
}

/* Additional styling for DatePicker component if needed */
.react-datepicker-wrapper,
.react-datepicker__input-container {
    margin: 0 10px;
}

/* Styling for the PieChart */
.recharts-pie {
    margin-top: 20px;
}
  
/* Customizing the Tooltip */
.recharts-default-tooltip {
    background-color: #fff !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

.recharts-wrapper {
    width: 100%;
}

  /* Customizing the Legend */
.recharts-legend-wrapper {  
    display: flex;
    flex-direction: column;
    gap: 10px;  
}
  
.chart-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

  /* Style for the legend item */
.recharts-legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px; /* Spacing between legend items */
  }

  /* Style for the legend text */
.recharts-legend-item-text {
    font-size: 14px;
    color: #333; /* Dark color for text */
  }
  
  .expense-breakdown {
    border-left: 2px solid #e0e0e0;
    padding-left: 20px;
  }
  
  .expense-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  
  .expense-name {
    font-weight: bold;
    color: #333;
  }
  
  .expense-value {
    font-weight: bold;
    color: #333;
  }
  
  .grand-total {
    border-top: 2px solid #e0e0e0;
    padding-top: 10px;
    margin-top: 10px;
  }

.title-sections-dashboard{
    width: 100%;
    color: #333;
    padding: 0;
}

.title-sections-dashboard h2 {
    color: #333;
    padding: 0;
    margin: 5px;
}

.expense-grand-total {
    width: 100%;
    margin: 15px;
}

.expense-grand-total h2 {
    padding: 5px;
    margin: 5px;
    color: #333;
}