.inventory-form-container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Two columns, with the second being narrower */
  gap: 20px;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.inventory-form,
.inventory-side-panel {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.inventory-form h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
input[type="date"],
select,
textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Make sure padding doesn't affect overall width */
}

input[type="file"] {
  border: none;
}

button {
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.inventory-side-panel {
  grid-column: span 2; /* Span the side panel across both columns */
}

/* Adjustments for vendor suggestions to match design */
.vendor-suggestions {
  /* ... existing styles ... */
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media query for responsiveness */
@media (max-width: 1024px) {
  .inventory-form-container {
      grid-template-columns: 1fr;
  }

  .inventory-side-panel {
      grid-column: auto;
  }
}

/* Additional styles for the product image section */
.product-image-container {
  text-align: center; /* Center the product image */
}

.product-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 4px; /* Optional: round corners for the image */
}

/* Styling for the visibility section */
.visibility-section {
  margin-bottom: 20px;
}

.visibility-option {
  margin-right: 10px;
}

/* Ensure that the margin display is visually appealing */
.margin-display {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.margin-display label {
  margin-right: 10px;
}

/* Style for the save and duplicate buttons */
.save-button {
  background-color: #28a745; /* Green color for the save button */
}

.duplicate-button {
  background-color: #ffc107; /* Yellow color for the duplicate button */
}
