.message-screen {
  max-width: 600px;
  margin: 10 auto;
  padding: 10px;
  background-color: #f9f9f9;
}

.message-list {
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 10px 0;
  padding: 12px;
  border-radius: 20px;
  max-width: 75%;
  font-size: 0.9rem;
}

.inbound {
  align-self: flex-start;
  background-color: #dfffe7;
  border-bottom-left-radius: 0;
}

.outbound {
  align-self: flex-end;
  background-color: #daf7ff;
  border-bottom-right-radius: 0;
}

.message-input {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.message-input input {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.message-input button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.message-sender {
  font-weight: bold;
}

.message-timestamp {
  font-size: 0.8em;
  color: #888;
}

.contact-list-container {
  height: auto; /* Let it expand dynamically */
  max-height: 90vh; /* Allow scrolling only if needed */
  overflow-y: scroll;
  border: 1px solid #ccc; /* Optional: Add a border for clarity */
  padding: 10px; /* Optional: Add some padding */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.contact-list {
  display: flex;
  flex-direction: column; /* Stack contacts vertically */
  gap: 10px; /* Add space between contact items */
}

.contact-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd; /* Optional: Add border around contact items */
  border-radius: 5px;
  background-color: #f9f9f9; /* Optional: Add background color */
  transition: background-color 0.3s; /* Smooth hover effect */
}

.contact-item:hover {
  background-color: #cec7c7;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-info h4 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.contact-info p {
  margin: 5px 0 0;
  font-size: 0.9rem;
  color: #666;
}

.unread-badge {
  background-color: #ff5722;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 5px 10px;
  border-radius: 50%;
  font-size: 0.9rem;
  margin-left: auto; /* Align the badge to the right */
}

.chat-conversation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f3f3f3;
}

.back-button {
  background-color: #333;
  color: #fff;
  padding: 12px;
  font-size: 1rem;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.input-bar {
  display: flex;
  padding: 15px;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
  align-items: center;
}

.attach-btn, .send-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.attach-btn {
  margin-right: 10px;
}

.send-btn {
  color: #28a745;
}

.chat-conversation h2 {
  margin: 0;
  padding: 20px;
  font-size: 1.2rem;
  background-color: #333;
  color: #fff;
  text-align: center;
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

/* Reduce image size in chat */
.chat-image {
  max-width: 33%; /* Scales to one-third of the container */
  cursor: pointer; /* Indicate clickable behavior */
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.chat-image:hover {
  transform: scale(1.05);
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.full-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
