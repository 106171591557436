.client-container {
    background-color: rgb(243, 244, 246);
    min-height: 100vh;
    padding: 2rem;
}

.client-selection {
    border-radius: 0.5rem;
    background-color: rgb(255, 255, 255);
    padding: 1.5rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0, 0, 0, 0.1), 0 4px 6px -4px rgb(0, 0, 0, 0.1);
}

.client-header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: rgb(229, 231, 235);
    padding-bottom: 1rem;
}

.client-header h1 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
}

.client-header span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: rgb(107, 114, 128);
}

.client-search {
    display: flex;
    align-items: center;
}

.input-search-client {
    width: 75%;
    margin: 0 15px 15px;
    padding: 1%;
}

.client-cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
}

.client-card {
    /* Adjust the styles to match TailwindCSS conventions */
    background-color: #f8fafc; /* This is bg-gray-50 */
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem; /* Add spacing between cards */
}

.client-card .client-name {
    font-weight: 600;
}

.client-card .client-info {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(75, 85, 99);
}

.client-initials {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    margin-right: 1rem;
    background: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-green-500 {
    background-color: #10b981; /* This is bg-green-500 */
    text-align: center;
    line-height: 2.5rem;
    border-radius: 9999px;
}

.text-white {
    color: #ffffff;
}

.rounded-full {
    border-radius: 9999px;
}

.h-10 {
    height: 2.5rem;
}

.w-10 {
    width: 2.5rem;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.mr-4 {
    margin-right: 1rem;
}

.font-semibold {
    font-weight: 600;
}

.text-gray-600 {
    color: #4b5563; /* This is text-gray-600 */
}

.text-sm {
    font-size: 0.875rem; /* This is text-sm */
}

.rounded-lg {
    border-radius: 0.5rem; /* This is rounded-lg */
}

.p-4 {
    padding: 1rem; /* This is p-4 */
}