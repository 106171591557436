.new-client-modal-overlay {
    position: fixed;
    top: 100px;
    left: 275px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
    z-index: 1000;
}

.new-client-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 80vh;
    overflow-y: auto;
}

/* Checkbox styling for better UX */
.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-container div {
    margin-right: 15px;
}

.input-field {
    border: rounded;
    width: full;
}