/* Header.css */
.app-header {
    background-color: #f8f9fa;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-section .shop-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
  }
  
  .quick-menu {
    display: flex;
    gap: 20px;
  }
  
  .quick-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #495057;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .quick-menu-item:hover {
    color: #007bff;
  }
  
  .quick-menu-icon {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  .right-section {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-info {
    font-size: 1rem;
    color: #343a40;
  }
  
  .logout {
    color: #dc3545;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .logout:hover {
    color: #a71d2a;
  }
  
  .login-link {
    text-decoration: none;
    font-size: 1rem;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .login-link:hover {
    color: #0056b3;
  }
  
  /* Responsive adjustments for all smaller devices */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .left-section .shop-name {
    font-size: 1.2rem; /* Smaller font size */
  }

  .quick-menu {
    justify-content: center;
    gap: 10px; /* Reduce space between menu items */
  }

  .quick-menu-item {
    font-size: 0.8rem; /* Smaller font size */
  }

  .quick-menu-icon {
    font-size: 1.2rem; /* Smaller icon size */
  }

  .right-section {
    font-size: 0.8rem; /* Adjust user info size */
    gap: 10px; /* Reduce space between elements */
  }

  .user-info {
    font-size: 0.9rem; /* Slightly smaller for user info */
  }

  .logout, .login-link {
    font-size: 0.9rem; /* Slightly smaller for links */
  }
}

/* Specific adjustments for iPhone 13, iPhone 12, and 12 Pro */
@media only screen 
     and (device-width: 390px) 
     and (device-height: 844px) 
     and (-webkit-device-pixel-ratio: 3) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .left-section .shop-name {
    font-size: 1.1rem; /* Adjusted for iPhone-specific dimensions */
  }

  .quick-menu-icon {
    font-size: 1.1rem; /* Smaller icons */
  }

  .quick-menu-item {
    font-size: 0.75rem; /* Compact font for iPhones */
  }

  .user-info {
    font-size: 0.85rem;
  }
}