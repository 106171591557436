.app {
  /*display: flex;
  flex-direction: row;*/
  text-align: center;
  overflow-x: hidden;
  background-color: #f0f2f5;
}

.app-topbar {
  /*width: 100%;
  /*position: fixed;*/
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 1rem;
  /*background-color: #282c34;*/
  color: #000;
  top: 0;
  z-index: 10;
}

.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  margin-left: 1rem;
}

.search-bar {
  padding: .5rem;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 15px;
  cursor: pointer;
}
/*
.sidebar {
  margin-top: 50px;
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px;
  background: #FFF;
  color: #333;
  padding: 1rem;
  transition: left 0.3s ease-in-out;
  font-size: .8em;
  z-index: 5;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 0.5rem 0;
  cursor: pointer;
}

.sidebar li ul {
  display: none;
  list-style-type: none;
  padding-left: 1rem;
}*/

/* Style for displaying submenus when the main menu is active 
.sidebar li ul.active {
  display: block;
}

.sidebar li ul li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  font-size: 0.9em;
  color: #333;
  cursor: pointer;
}*/

.app-content {
  margin-top: 50px;
  padding-top: 50px;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: auto;
  transition: margin-left 0.3s ease-in-out;
  position: relative;
  left: 0;
  top: 0;
  flex-grow: 1;
  padding: 2rem;
  background-color: #f0f2f5;
  height: calc(100vh - 50px); /* Adjust for fixed header height */
}

.app-content.sidebar-open {
  margin-left: 250px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


button {
  background-color: #282c34 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

button:hover {
  background-color: #282c34 !important;
  color: #fff !important;
}

.rbc-button-link {
  background: none !important;
  color: #000 !important;
}