.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pie-chart {
    width: 50%;
    margin-bottom: 20px;
  }
  
  .chart-legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .chart-data {
    height: 231px !important;
    width: 250px !important;
  }