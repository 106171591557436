.vendor-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .vendor-form h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .vendor-form select,
  .vendor-form input[type='text'] {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .vendor-search-bar input[type='text'] {
    width: 60%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .vendor-search-bar button {
    width: 185px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .vendor-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .vendor-form button:disabled {
    background-color: #ccc;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .vendor-form select,
    .vendor-form input[type='text'] {
      width: 100%; /* Adjust to full width on smaller screens */
    }
  }

  .vendor-search-results {
    margin-top: 20px;
  }

  .vendor-search-results table {
    width: 100%;
    /* Other table styles */
  }

  .vendor-search-results th, .vendor-search-results td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }