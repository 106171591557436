.expense-form-container {
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.expense-form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.expense-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

.expense-form-container .form-section {
  background: #ffffff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.expense-form-container .form-section .section-title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #34495e;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.expense-form-container .form-row {
  display: flex;
  gap: 15px;
}

.expense-form-container .form-row > div {
  flex: 1;
}

.expense-form-container label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
  display: block;
}

.expense-form-container input[type="number"],
.expense-form-container select,
.expense-form-container input[type="date"],
.expense-form-container input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1em;
}

.expense-form-container input[type="checkbox"] {
  margin-right: 8px; 
}

.expense-form-container .checkbox-group label {
  margin: 0;
  font-size: 1em;
}

.expense-form-container .checkbox-group input[type="number"] {
  width: 10%;
}

.expense-form-container button {
  padding: 12px 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.expense-form-container button:hover {
  background-color: #003d82;
}

.expense-form-container .section-heading {
  margin-top: 20px;
  font-size: 1.2em;
  color: #444;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.checkbox-group,
.account-group {
display: flex;
align-items: center;
gap: 10px;
}

.bank-account-label {
display: flex;
align-items: center;
gap: 8px;
margin-bottom: 10px;
}

.tooltip {
display: inline-block;
margin-left: 8px;
font-size: 0.8em;
color: #888;
cursor: pointer;
}

.tooltip:hover {
color: #555;
}

.form-section ul {
list-style: none;
padding: 0;
margin: 10px 0;
}

.form-section ul li {
margin: 5px 0;
color: #333;
}