.cash-register-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.cash-register-form select, 
.cash-register-form button {
  margin: 10px;
  padding: 5px 10px;
}

.cash-register-form button {
  cursor: pointer;
}

.denominations-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.denomination-input {
  display: flex;
  flex-direction: column;
}

.denomination-input label {
  margin-bottom: 5px;
}

.denomination-input input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.total-amount {
  flex:2;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* or a fixed width */
  max-width: 100%; /* Maximum width */
  max-height: 80%; /* Maximum height */
  overflow-y: auto; /* Enable scrolling if content is too long */
}

.close-modal {
  float: right;
  cursor: pointer;
}