/* Style.css */

.booking-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 12px;
    /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: linear-gradient(135deg, #3498db, #8e44ad); */
}

.booking-heading {
    font-family: 'Your Stylish Font', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-align: center;
}

.shop-name {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #e74c3c;
    text-align: center;
}

.calendar-container {
    margin-top: 20px;
    height: 500px;
    background-color: #ecf0f1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

.calendar-type-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-type-container label {
    font-size: 18px;
    margin-right: 10px;
    color: #000;
}

.calendar-type-container select {
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #282c34 !important;
  color: #fff !important;
    cursor: pointer;
}

.time-selection-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.time-selection-container label {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.time-selection-container input {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
    margin-bottom: 15px;
}

.button {
    background-color: #27ae60;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.modal {
   /* max-width: 500px; */
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: #333;
}

.client-info-row.hidden {
    display: none;
  }

  
.button:hover {
    background-color: #219653;
}

.calendar-container.selected {
    border: 2px solid #4caf50; 
    background-color: #e0f7fa; 
  }

/* Add these styles to your existing Style.css file or create a new one */

/* .time-selection-container.selected {
  } */
  
  .client-info-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
  }
  
  .client-info-table th,
  .client-info-table td {
    border: none; /* Remove borders from table cells */
    padding: 8px;
    text-align: left;
  }
  
  .client-info-table th {
    background-color: #f2f2f2;
  }
  
  .client-info-row:hover {
    background-color: #f5f5f5;
  }
  
  .client-info-row td {
    max-width: 150px; /* Adjust the max-width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .ReactModal__Content, .ReactModal__Content--after-open {
    margin-top: 50px;
  }

  .shop-sidebar {
    text-align: left;
    text-decoration: none;
    list-style: none;
    padding-top: 10px;
  }

  .shop-sidebar li {
    text-align: left;
    text-decoration: none;
    list-style: none;
    padding: 20px 0;
  }


  .rbc-day-slot .rbc-events-container {
    margin-right: 0px !important;
  }

  .rbc-event-label {
    display: none;
  }

  /* custom-calendar.css */
.rbc-event {
  /*height: auto !important;
  overflow: visible !important;*/
  padding: 0px 0px;
  box-sizing: border-box;
}


  select {
    cursor: pointer;
  }

  select option {
    cursor: pointer !important;
  }


  .modal-open .modal {
    background: #fff0;
  }


  .custom-modal-width {
    width: 90vw !important;
    max-width: 90vw !important;
  }
  
.cart-btn {
  background-color: #4caf50 !important;
}

.btn-close {
  background-color: #FFF !important;
}